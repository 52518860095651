import cn from 'clsx'

import { BaseText } from '@/components/Text/Text'
import type { TextComponentProps } from '@/components/Text/Text'

import css from './Espa.module.css'

type EspaSize = 'espa-xl' | 'espa-lg' | 'espa-md' | 'espa-sm' | 'espa-prose'

export type EspaProps = TextComponentProps<EspaSize>

export function Espa({ size = 'espa-md', children, className, ...rest }: EspaProps) {
    return (
        <BaseText className={cn(css.espaText, className)} cssRules={css} size={size} {...rest}>
            {children}
        </BaseText>
    )
}
