import cn from 'clsx'

import { Text } from '@/components/Text/Text'
import type { TextProps } from '@/components/Text/Text'

import css from './Heading.module.css'

export type HeadingProps = TextProps & { level: '1' | '2' | '3' | '4' | '5' | '6' }

const levelTagMap = {
    '1': 'h1',
    '2': 'h2',
    '3': 'h3',
    '4': 'h4',
    '5': 'h5',
    '6': 'h6',
} as const

export function Heading({ level, className, ...args }: HeadingProps) {
    return <Text className={cn(css.heading, className)} tag={levelTagMap[level]} {...args} />
}
