import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import type { BoxProps } from '@/components/Box/Box'

import css from './Card.module.css'

export type CardProps = BoxProps

/**
 * Represents an interactive card within the design system.
 * This is more likely to be used as a container for other components, within
 * Marketist but we will still expose it, just-in-case. If being used outside
 * of Marketist, don't forget to properly set the type and other accessible
 * properties.
 */
export function Card({ className, children, disabled, ...rest }: CardProps) {
    return (
        <Box className={cn(className, css.card, { [css.disabled]: disabled })} {...rest}>
            {children}
        </Box>
    )
}
