import { Layout, Seo } from 'components'
import * as Sections from 'components/home-page'
import { getCommonServerSideProps } from 'services/getCommonServerSideProps'

import type { GetServerSidePropsContext } from 'next'

export default function Home() {
    return (
        <>
            <Seo
                title="Doist: The remote company behind Todoist & Twist"
                description="Doist inspires the workplace of the future by creating simple yet powerful productivity software that promotes a more fulfilling way to work and live."
                images={[
                    {
                        width: 1200,
                        height: 628,
                        alt: 'The Fully Remote Company Behind Todoist & Twist',
                        url: `${process.env.NEXT_PUBLIC_BASE_URL}/og-images/og-image-home.jpg`,
                    },
                ]}
            />

            <Layout>
                <Sections.HeroSection />
                <Sections.ProductsSection />
                <Sections.MapSection />
                <Sections.ArticlesSection />
            </Layout>
        </>
    )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
    const commonProps = getCommonServerSideProps(ctx)

    return {
        props: {
            ...commonProps,
        },
    }
}
