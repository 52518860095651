import type { ReactElement } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Card } from '@/components/Card/Card'
import { Hairline } from '@/components/Hairline/Hairline'
import { Image, type ImageProps } from '@/components/image/image'
import type { MetaPill } from '@/components/meta-pill/meta-pill'
import { BlockQuote } from '@/components/Text/BlockQuote'
import { Heading } from '@/components/Text/Heading'
import { Text } from '@/components/Text/Text'

import css from './blog-card.module.css'

type CommonProps = {
    title: string
    href: string
    size?: 'base' | 'large'
    pills?: ReactElement<typeof MetaPill>[]
    children?: React.ReactNode
    disabled?: boolean
    withBorder?: boolean
    target?: HTMLAnchorElement['target']
}

type ImageVariant = Pick<ImageProps, 'src' | 'alt' | 'width' | 'height'>

type TextVariant = {
    src?: never
}

export type BlogCardProps = CommonProps & (ImageVariant | TextVariant)

export function BlogCard({
    title,
    href,
    size = 'base',
    pills = [],
    disabled,
    children,
    src: imageSrc,
    target,
    withBorder = false,
    ...props
}: BlogCardProps) {
    const variant = imageSrc ? 'image' : 'text'
    const isLarge = size === 'large'

    return (
        <Card
            className={cn(
                css.blogLinkContainer,
                css[size],
                { [css.disabled]: disabled },
                css[`${variant}Variant`],
            )}
            tag="article"
        >
            <a className={css.link} href={href} title={title} aria-label={title} target={target}>
                {title}
            </a>
            {imageSrc ? (
                <Image className={css.image} src={imageSrc} width={340} height={180} {...props} />
            ) : children ? (
                <>
                    <BlockQuote size="quote-base" tag="p" className={css.highlightedExcerpt}>
                        {children}
                    </BlockQuote>
                    <Hairline spacing={24} />
                </>
            ) : null}
            <Box className={cn(css.bodyContainer, { [css.withBorder]: withBorder })}>
                <Heading
                    size={isLarge ? 'major-sm' : 'minor-base'}
                    weight="semibold"
                    className={css.title}
                    level="2"
                >
                    {title}
                </Heading>
                {children && imageSrc ? (
                    <Text className={css.description} size={isLarge ? 'body-sm' : 'body-xs'}>
                        {children}
                    </Text>
                ) : null}
            </Box>
            {pills.length ? (
                <Box tag="ul" className={css.pills}>
                    {pills}
                </Box>
            ) : null}
        </Card>
    )
}
